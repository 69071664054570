import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
import { polyfill } from "seamless-scroll-polyfill";
import './Fonts/fonts.css';
import './NM001.css';

function NM001() {
  const [HeaderVideoAlpha, setHeaderVideoAlpha] = useState();
  const [CloudAlpha, setCloudAlpha] = useState();
  const [BackgroundAlpha, setBackgroundAlpha] = useState();
  const [isTouch, setIsTouch] = useState();
  const [windowHeight, setWindowHeight] = useState();
  const clouds = useRef();
  const store = useRef();

  useEffect(() => {
    setIsTouch(isMobile());
    polyfill();
    playVideos();
    setWindowHeight(window.innerHeight);
    document.getElementById("container").addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    window.addEventListener("resize", handleResize);
    window.addEventListener("focus", playVideos);
    return () => {
      document.getElementById("container").removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("focus", playVideos);
    };
  }, []);

  const handleScroll = () => {
    setHeaderVideoAlpha(document.getElementById("container").scrollTop / (window.innerHeight / 2));

    if (clouds.current.getBoundingClientRect().y > 0) {
      setCloudAlpha((clouds.current.getBoundingClientRect().y) / (window.innerHeight / 2));
    } else {
      setCloudAlpha(1 - (clouds.current.getBoundingClientRect().y + (window.innerHeight)) / (window.innerHeight / 2));
    }

    if (store.current.getBoundingClientRect().y > 0) {
      let progress = 1 - (store.current.getBoundingClientRect().y) / (window.innerHeight / 2);
      setBackgroundAlpha(progress * 255);
    } else {
      setBackgroundAlpha(255);
    }
  }

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  }

  const fadeToBlack = () => {
    setBackgroundAlpha(0);
  }

  const scrollToStore = () => {
    store.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  const playVideos = () => {
    let videos = document.querySelectorAll('video'), i;
    for (i = 0; i < videos.length; ++i) {
     videos[i].play();
    }
  }

  const isMobile = () => {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    }
    if ('ontouchstart' in window) {
        return true;
    }
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }

  return (
    <div 
      id="container"
      style={{
        color: 'rgba(' + (255 - BackgroundAlpha) + ", " + (255 - BackgroundAlpha) + ", " + (255 - BackgroundAlpha) + ", 1)",
        backgroundColor: 'rgba(' + BackgroundAlpha + ", " + BackgroundAlpha + ", " + BackgroundAlpha + ", 1)"
      }}
      className={isTouch ? "touch" : "no-touch"}>
      <Helmet>
        <meta property="og:title" content="No Moderation" />
        <meta property="og:url" content="https://nomoderation.com" />
        <meta property="og:description" content="The Durability of the Delicate" />
        <meta property="og:image" content="https://nomoderation.com/og-image.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="No Moderation" />
        <meta name="twitter:description" content="The Durability of the Delicate" />
        <title>No Moderation</title>
      </Helmet>
      <div 
        style={{
          color: 'rgba(' + (255 - BackgroundAlpha) + ", " + (255 - BackgroundAlpha) + ", " + (255 - BackgroundAlpha) + ", 0.5)",
        }}
        className="section navigation">
        <div className="grid">
          <div className="cs5 ce6 sfui">
            <a onClick={scrollToStore} className="shop">Shop</a>
          </div>
          <div className="cs7 ce8 sfui">
            <NavLink onClick={fadeToBlack} to="/"><em>No Moderation</em></NavLink>
          </div>
        </div>
      </div>

      <div 
        id="video-1"
        style={{
          opacity: 1 - HeaderVideoAlpha
        }}
        dangerouslySetInnerHTML={{ __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            type="video/mp4" 
            src="img/scarf_intro_01.mp4"/>
        `}}>
      </div>

      <div 
        id="cloud"
        style={{
          opacity: 1 - CloudAlpha
        }}>
        <img alt="" src="img/cloud_moving.jpg"/>
      </div>

      <div 
        id="section-1"
        className="section hero"
        style={{minHeight: windowHeight}}>
        <div className="grid">
          <div className="scribble1">
            <img alt="" src="img/scribble-01.svg"/>
          </div>
          <div className="scribble2">
            <img alt="" src="img/scribble-02.svg"/>
          </div>
          <div style={{gridRowStart: 1, gridRowEnd: 2}} className="cs1 ce7">The Durability</div>
          <div style={{gridRowStart: 2, gridRowEnd: 3}} className="cs5 ce7">of the</div>
          <div style={{gridRowStart: 3, gridRowEnd: 4}} className="cs4 ce7">Delicate</div>
        </div>
      </div>

      <div id="section-2" className="section content">
        <div className="grid">
          <div className="cs2 ce6">
            Before time, there was sun. Before sun, there was a bang. And nothing has ever stopped since. The clock continues to tick. So then what does it take to stand the test of time? To become timeless? Strength? Resilience?
          </div>
        </div>
      </div>

      <div id="section-3" className="section content">
        <div className="grid">
          <div 
            className="cs1 ce4"
            dangerouslySetInnerHTML={{ __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              type="video/mp4" 
              src="img/scarf_intro_02.mp4"/>
          `}}>
          </div>
          <div className="cs4 ce7">
            <img alt="" src="img/scarf_intro_03.jpg"/>
          </div>
          <div className="cs2 ce6">
            <img alt="" src="img/scarf_intro_04.jpg"/>
          </div>
        </div>
      </div>

      <div id="section-4" className="section content">
        <div className="grid">
          <div className="cs2 ce6">
            If a spider’s silk is stronger than steel, why can’t delicacy survive the long haul? Nefertiti may have been the first to see durability in a surprising shape.
          </div>
        </div>
      </div>

      <div id="section-5" className="section content">
        <div className="grid">
          <div className="cs1 ce5">
            <img alt="" src="img/scarf_intro_05.jpg"/>
          </div>
          <div className="cs5 ce7 flex-end caption sfui">
            The earliest scarf in recorded history belonged to the Egyptian pharaoh, Nefertiti in 1350 BC &mdash; a finely woven style she displayed with with her iconic headdress.
          </div>
          <div className="cs2 ce6">
            <img alt="" src="img/scarf_intro_06.jpg"/>
          </div>
        </div>
      </div>

      <div id="section-6" className="section content">
        <div className="grid">
          <div className="cs2 ce6">
            The winds that buried Nefertiti’s empire brought a young man named Thierry Hermès to Paris. His name would become synonymous with carrying on a tradition of movement.
          </div>
        </div>
      </div>

      <div id="section-7" className="section content">
        <div className="grid">
          <div className="cs1 ce5">
            <img alt="" src="img/scarf_intro_07.jpg"/>
          </div>
          <div className="cs3 ce5 flex-start caption sfui">
            As a child, Thierry Hermès lost his entire family to disease and war. An orphan, he moved to Paris in 1821. To make ends meet he began hand-crafting horse harnesses and bridles for elites. A generation later, Hermès’ son took over the family business and expanded their offerings to saddles, saddle bags and, yes, scarves.
          </div>
          <div className="cs5 ce7">
            <img alt="" src="img/scarf_intro_08.jpg"/>
          </div>
        </div>
      </div>

      <div id="section-8" className="section clouds" ref={clouds}>
        <div className="grid">
          <div className="scribble3">
            <img alt="" src="img/scribble-04.svg"/>
          </div>
          <div className="scribble4">
            <img alt="" src="img/scribble-05.svg"/>
          </div>
          <div className="scribble5">
            <img alt="" src="img/scribble-06.svg"/>
          </div>
          <div style={{gridRowStart: 2, gridRowEnd: 3}} className="cs2 ce6">When Europe went to war and the skies turned black</div>
          <div style={{gridRowStart: 3, gridRowEnd: 4}} className="cs5 ce7">white scarves</div>
          <div style={{gridRowStart: 4, gridRowEnd: 5}} className="cs4 ce7">tethered out of cockpits</div>
        </div>
      </div>

      <div id="section-9" className="section content">
        <div className="grid">
          <div className="cs3 ce5 flex-start caption sfui">
            White silk scarves were standard issue for fighter pilots during WWI. Why? Open air cockpits meant pilots dealt with engine exhaust and bugs. White cloth made it easy to find a clean spot to wipe with.
          </div>
        </div>
      </div>
              
      <div id="section-10" className="section content">
        <div className="grid">
          <div className="cs2 ce6">
            In the years that followed, the scarf movement was at a fever pitch. Everyone wanted to look like the heroic daredevils in the air. Scarves became mass produced in factories, but at Hermès? The hands at the silk screens never stood still, stubbornly dedicated to their craftsmanship.
          </div>
        </div>
      </div>
              
      <div id="section-11" className="section content">
        <div className="grid">
          <div className="cs2 ce6">
            <img alt="" src="img/scarf_intro_10.jpg"/>
          </div>
          <div className="cs2 ce4 flex-start caption sfui">
            It wasn’t until Thierry’s great grandson took the reins, that Hermès began to resemble the company we know today. Theirry’s great grandson ditched the equestrian accessories and decided to focus solely on perfecting luxury clothing.
          </div>
        </div>
      </div>

      <div id="section-12" className="section content">
        <div className="grid">
          <div className="cs2 ce6">
            If you own a Hermès, it’s a permanent part of your wardrobe. From the runways of Milan, Italy to the driveways of Milan, Michigan, the smooth, enduring fibers never lose their shape.
          </div>
        </div>
      </div>

      <div id="section-13" className="section content">
        <div className="grid">
          <div className="cs3 ce6">
            <img alt="" src="img/scarf_intro_11.jpg"/>
          </div>
          <div 
            className="cs1 ce4"
            dangerouslySetInnerHTML={{ __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              type="video/mp4" 
              src="img/scarf_intro_09.mp4"/>
          `}}>
          </div>
          <div className="cs4 ce6 flex-start caption sfui">
            Hermès silk is produced in-house from mulberry moth silkworms. It is twice as heavy as other silks, woven in a tight twill that maintains integrity. Twist, tie, or wring that baby and it is always going to return to a perfect square.
          </div>
        </div>
      </div>

      <div id="section-14" className="section content">
        <div className="grid">
          <div className="cs2 ce6">
            This timeless approach allows Hermès to keep moving at the Hermès pace.
          </div>
        </div>
      </div>

      <div id="section-15" className="section content">
        <div className="grid">
          <div className="cs2 ce5">
            <img alt="" src="img/scarf_intro_12.jpg"/>
          </div>
          <div className="cs1 ce4">
            <img alt="" src="img/scarf_intro_13.jpg"/>
          </div>
          <div className="cs4 ce6 flex-end caption sfui">
            It takes eighteen months to make one Hermès scarf. Starting with the commission of an original artwork, through the pain staking process of creating hand-engraved silk screens, no shortcut is taken. Hermès famously hand-rolls and hand stitches the edges. The tubular edge holds the shape and prevents fraying, while creating a signature accent that Margiela later applied to Hermès clothing.
          </div>
          <div className="cs4 ce7">
            <img alt="" src="img/scarf_intro_14.jpg"/>
          </div>
        </div>
      </div>

      <div id="section-16" className="section content">
        <div className="grid">
          <div className="cs2 ce6">
            Some things are worth the wait. Nefertiti would be proud. Good thing she never had a watch. 
          </div>
        </div>
      </div>

    {/* BEGIN STORE  */}

      <div ref={store} id="store" className="section content">
        <div className="grid">
          <div
            style={{
              position: "relative"
            }} 
            className="cs2 ce6 large">
              Shop the collection: Hermès, Dior, and more.
            <div id="covid-banner" className="sfui caption">
              To help with<br/> 
              the COVID-19<br/>
              outbreak, 100% of<br/>
              sales are going to the<br/>
              <span><a href="http://cdcfoundation.org" target="blank">CDC Foundation</a></span>
            </div>
          </div>
        </div>
      </div>

      <div className="section content title lead">
        <div className="grid">
          <div className="cs2 ce6">
            Hermès
            <div className="large"><em>Napoleon</em> by Phillipe LeDoux</div>
          </div>
        </div>
      </div>

      <div className="section content shop"> 
        <div className="grid">
          <div className="cs1 ce4 image">
            <img alt="" src="img/napoleon_01.jpg"/>
            <div className="cs1 ce3 flex-end caption sfui">
              <em>Napoleon</em> is one of the canonical Hermès designs, the definition of a classic. Designed in 1963 and popular enough to be reissued, this one is from 1989.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/napoleon_02.jpg"/>
            <div className="cs4 ce6 flex-end caption sfui">
              It was designed by the prolific Phillipe LeDoux, who made more than 90 scarves during his 30 years working with Hermès.
            </div>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/napoleon_04.jpg"/>
            <div className="caption sfui">
              This design highlights Napoleon’s five greatest achievements. It’s rich with symbolism down to the smallest details.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/napoleon_06.jpg"/>
            <div className="caption sfui">
              The repetition of the numbers 2 and 5, are symbolic: Napoleon was coronated on Dec 2, 1804 and died on May 5, 1821. The bee, a symbol of immortality, was the emblem of Napoleon’s empire.
            </div>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/napoleon_03.jpg"/>
            <div className="caption sfui">
              For this design Hermès went the extra mile &mdash; not only are the bees screened onto the scarf, but there actually woven into the scarf in an intricate jacquard design.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/napoleon_05.jpg"/>
          </div>
        </div>
      </div>

      <div className="section content">
        <div className="grid">
          <div className="cs2 ce6 large">
            <p><strike><a href="https://no-moderation.myshopify.com/collections/vintage-silk-scarves/products/hermes-napoleon-scarf-by-phillipe-ledoux" target="blank" className="buy">Buy Hermès <em>Napoleon</em> by Phillipe LeDoux for $299</a></strike></p>
          </div>
        </div>
      </div>

      <div className="section content title">
        <div className="grid">
          <div className="cs2 ce6">
            Hermès
            <div className="large"><em>Kachinas</em> by Kermit Oliver</div>
          </div>
        </div>
      </div>

      <div className="section content shop">
        <div className="grid">
          <div className="cs1 ce4 image">
            <img alt="" src="img/kachinas_01.jpg"/>
            <div className="caption sfui">
              Kermit Oliver is a life-long, night-shift postal worker in Waco, Texas &mdash; and the only American ever to design scarves for Hermès.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/kachinas_02.jpg"/>
            <div className="caption sfui">
              Because of his unique story and surreal style Kermit’s scarves are highly sought-after, with new ones routinely selling out.
            </div>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/kachinas_03.jpg"/>
            <div className="caption sfui">
              The figures in this scarf are kachina dolls &mdash; each representing one of the 500 divine and ancestral spirits honored by the Pueblo cultures of the Southwest.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/kachinas_04.jpg"/>
            <div className="caption sfui">
              Kachina dolls are given to children not as toys but as training tools. Each child is expected to recognize and understand the symbolism associated with hundreds of these ancestral spirits.
            </div>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/kachinas_05.jpg"/>
            <div className="cs1 ce3 flex-end caption sfui">
              <p><em>Kachinas</em> is a perfect encapsulation of Kermit’s style: vibrant “painted collages,” heavy with symbolism and rooted in the traditions of the Southwest. Certainly not what you expect from a French luxury brand.</p>
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/kachinas_06.jpg"/>
            <div className="cs4 ce6 flex-end caption sfui">
              A peyote button sits at the center of the design. It’s a psychedelic flowering cactus native to the Southwest and utilized for centuries as part of local Native American religious ceremonies.
            </div>
          </div>
        </div>
      </div>
  
      <div className="section content">
        <div className="grid">
          <div className="cs2 ce6 large">
            <p><strike><a href="https://no-moderation.myshopify.com/collections/vintage-silk-scarves/products/hermes-kachinas-by-kermit-oliver" target="blank" className="buy">Buy Hermès <em>Kachinas</em> by Kermit Oliver for $599</a></strike></p>
          </div>
        </div>
      </div>

      <div className="section content title">
        <div className="grid">
          <div className="cs2 ce6">
            Hermès
            <div className="large"><em>Soirée a la Opera</em> by Jean-Louis Clerc</div>
          </div>
        </div>
      </div>

      <div className="section content shop">
        <div className="grid">
          <div className="cs1 ce4 image">
            <img alt="" src="img/soiree_01.jpg"/>
            <div className="caption sfui">
              Originally released in 1950, Soirée a la Opera is a museum piece on silk. This is the Fall 2010 reissue.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/soiree_02.jpg"/>
            <div className="caption sfui">
              Jean-Louis Clerc, and his 10 Hermès designs, are credited with introducing a less regimented style to the classical form.
            </div>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/soiree_03.jpg"/>
            <div className="caption sfui">
              This scene takes place at the Palais Garnier Opera House. It was designed by relatively-unknown architect, Charles Garnier, after winning a contest advanced by emperor Napoleon III (nephew of LeDoux’s Napoleon).
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/soiree_04.jpg"/>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/soiree_05.jpg"/>
            <div className="caption sfui">
              The purling movement captured in the image, complemented by the inherent flow of the silk medium, makes even still images of the scarf appear animated.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/soiree_06.jpg"/>
            <div className="caption sfui">
              The variable representation of detail, pops of color, and impressionistic strokes combine to create an immersive scene as though you’re watching it unfold in front of you.
            </div>
          </div>
        </div>
      </div>
              
      <div className="section content">
        <div className="grid">
          <div className="cs2 ce6 large">
            <p><strike><a href="https://no-moderation.myshopify.com/collections/vintage-silk-scarves/products/hermes-soiree-a-l-opera-by-jean-louis-clerc" target="blank" className="buy">Buy Hermès <em>Soirée a la Opera</em> by Jean-Louis Clerc for $399</a></strike></p>
          </div>
        </div>
      </div>

      <div className="section content title lead">
        <div className="grid">
          <div className="cs2 ce6">
            Christian Dior
            <div className="large"><em>Geometric</em></div>
          </div>
        </div>
      </div>

      <div className="section content shop">
        <div className="grid">
          <div className="cs1 ce4 image">
            <img alt="" src="img/dior_01.jpg"/>
            <div className="caption sfui">
              A young Yves Saint Laurent set the mold for bold designs like this from Dior in the late 50’s. He famously took over the company at 21 after Christian Dior unexpectedly passed away.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/dior_02.jpg"/>
            <div className="caption sfui">
              His work is credited with saving the Dior label, but after less than 3 years at the helm, he was drafted into the French Army. Upon return he found that Dior had replaced him and so began his eponymous label.
            </div>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/dior_03.jpg"/>
            <div className="caption sfui">
              Dior’s mid-century silk scarves captured Saint Laurent’s energy, flying in the face of tradition and reimagining them in the modernist movement that was sweeping through art, architecture, and furniture.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/dior_04.jpg"/>
            <div className="caption sfui">
              While “Christian Dior” logo was simplified to “Dior” in the late 90’s, the font which was designed by Georges Peignot has remained untouched since the 40’s. A timeless icon.
            </div>
          </div>
        </div>
      </div>
        
      <div className="section content">            
        <div className="grid">
          <div className="cs2 ce6 large">
            <p><strike><a href="https://no-moderation.myshopify.com/collections/vintage-silk-scarves/products/christian-dior-geometry-scarf" target="blank" className="buy">Buy <em>Geometric</em> from Christian Dior for $199</a></strike></p>
          </div>
        </div>
      </div>

      <div className="section content title">
        <div className="grid">
          <div className="cs2 ce6">
            Moschino
            <div className="large"><em>I Love Fashion</em></div>
          </div>
        </div>
      </div>

      <div className="section content shop">
        <div className="grid">
          <div className="cs1 ce4 image">
            <img alt="" src="img/moschino_01.jpg"/>
            <div className="caption sfui">
              A high-end parody of the classic Hermès scarf.  Here Moschino’s throwing shade at luxurious, extravagant fashion &mdash; with its own luxurious, extravagant silk scarf. 
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/moschino_02.jpg"/>
            <div className="cs4 ce6 flex-end caption sfui">
              This scarf perfectly encapsulates Franco Moschino’s approach. The central conceit of his brand was to wrap fashion’s symbols in irony as critique of the industry.
            </div>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/moschino_03.jpg"/>
            <div className="caption sfui">
              The Moschino duck is a recurring motif for the brand, first appearing on a runway dress in the 90’s. The duck was the brand’s cartoonish icon of conspicuous consumption (and meta-commentary of it’s customers if you really think about it).
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/moschino_04.jpg"/>
            <div className="caption sfui">
              Moschino had a love/hate relationship with fashion. He often said that fashion didn’t actually exist, but if it did it would represent the freedom to wear anything at any time.
            </div>
          </div>
        </div>
      </div>

      <div className="section content">
        <div className="grid">
          <div className="cs2 ce6 large">
            <p><strike><a href="https://no-moderation.myshopify.com/collections/vintage-silk-scarves/products/moschino-i-love-fashion-scarf" target="blank" className="buy">Buy <em>I Love Fashion</em> from Moschino for $199</a></strike></p>
          </div>
        </div>
      </div>

      <div className="section content title">
        <div className="grid">
          <div className="cs2 ce6">
            Salvatore Ferragamo
            <div className="large"><em>Leopard</em></div>
          </div>
        </div>
      </div>

      <div className="section content shop">
        <div className="grid">
          <div className="cs1 ce4 image">
            <img alt="" src="img/ferragamo_01.jpg"/>
            <div className="caption sfui">
              Another evolution in the silk scarf &mdash; Ferragamo took inspiration from his famous leopard prints to craft a robust, edge-to-edge “single-image” design.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/ferragamo_02.jpg"/>
            <div className="caption sfui">
              Gone are the symmetry and patternized designs of the classical scarf, and in their place, a rich, detailed world that &mdash; when unfurled, invites viewers to lean in closer and immerse themselves in the mise-en-scene. 
            </div>
          </div>
          <div className="cs1 ce4 image">
            <img alt="" src="img/ferragamo_03.jpg"/>
            <div className="caption sfui">
              This scarf is particularly arresting when worn, offering furtive peeks at bold colors and shapes that hint at a secret world contained within.
            </div>
          </div>
          <div className="cs4 ce7 image">
            <img alt="" src="img/ferragamo_04.jpg"/>
            <div className="caption sfui">
              Why leopard prints? Over the years the print came to signify Salvatore’s obsession with the rare and exotic, playing a central role in the brand’s iconography since.
            </div>
          </div>
        </div>
      </div>

      <div 
        style={{ paddingBottom: 0 }}
        className="section content">
        <div className="grid">
          <div className="cs2 ce6 large">
            <p><strike><a href="https://no-moderation.myshopify.com/collections/vintage-silk-scarves/products/salvatore-ferragamo-leopard-scarf" target="blank" className="buy">Buy <em>Leopard</em> from Salvatore Ferragamo for $299</a></strike></p>
          </div>
        </div>
      </div>

      <div 
        style={{ paddingTop: 0 }}
        className="section content colophon">
        <div className="grid">
          <div className="cs3 ce5">
            <img alt="" src="img/scribble-07.svg"/>
          </div>
          <div 
            style={{
              textAlign: "right",
            }}
            className="cs1 ce4 caption sfui colophonLeft">
            Photography<br/>
            Videography<br/><br/>
            Styling<br/>
            Modeling<br/><br/>
            Writing<br/><br/>
            Music<br/>
            Engineering
          </div>
          <div className="cs4 ce7 caption sfui colophonRight">
            <p><a href="https://www.instagram.com/jack.bool/" target="blank">Jack Bool</a></p>
            <p><a href="https://www.instagram.com/jack.bool/" target="blank">Jack Bool</a></p>
            <p><a href="https://www.instagram.com/karina_vlastnik/" target="blank">Karina Vlastnik</a></p>
            <p><a href="https://www.instagram.com/here.studio.california/" target="blank">Rachel Cubra</a></p>
            <p><a href="https://www.instagram.com/ajadewolfmoura/" target="blank">Aja DeWolf Moura</a></p>
            <p><a href="https://www.instagram.com/heishendrix/" target="blank">Justus Hendrix</a></p>
            <p><a href="https://www.instagram.com/thegoondocks.tv/" target="blank">The Goondocks</a></p>
            <p><a href="https://www.instagram.com/kevinparkerflynn" target="blank">Kevin Flynn</a></p>
            <p><a href="https://www.instagram.com/kyleflynn" target="blank">Kyle Flynn</a></p>
            <p><a href="https://www.instagram.com/_andychung/" target="blank">Andy Chung</a></p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default NM001;
