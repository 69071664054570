import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import './Fonts/fonts.css';
import './NM003.css';

function NM003() {
  
  const [isTouch, setIsTouch] = useState();
  const [windowHeight, setWindowHeight] = useState();

  useEffect(() => {
    setIsTouch(isMobile());
    playVideos();
    setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    window.addEventListener("focus", playVideos);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("focus", playVideos);
    };
  }, []);
  
  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  }

  const playVideos = () => {
    let videos = document.querySelectorAll('video'), i;
    for (i = 0; i < videos.length; ++i) {
     videos[i].play();
    }
  }

  const isMobile = () => {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    }
    if ('ontouchstart' in window) {
        return true;
    }
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }
  return (
    <div id="nm003">
    
      <Helmet>
        <meta property="og:title" content="Packable Anorak with C'H'C'M'" />
        <meta property="og:url" content="https://nomoderation.com" />
        <meta property="og:description" content="CHCM & No Moderation" />
        <meta property="og:image" content="https://nomoderation.com/img-nm003/003-og.png" />
        <meta property="og:image:width" content="1920"/>
        <meta property="og:image:height" content="1080"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Packable Anorak with C'H'C'M'" />
        <meta name="twitter:description" content="A special collaboration between C'H'C'M' and No Moderation." />
        <title>Packable Anorak with C'H'C'M'</title>
      </Helmet>
        
        <div class="clipper"></div>

    	<div class="header">
    		<a href="http://www.chcmshop.com" class="header-item">chcm</a>
    		<a href="https://www.instagram.com/no_moderation" class="header-item">no moderation</a>
    	</div>
	
    	<div class="footer">
    		<a href="https://chcmshop.com/collections/no-moderation" class="footer-item">shop</a>
    	</div>
		
      <video loop muted autoPlay playsInline id="vid">
         <source type="video/mp4" src="img-nm003/bg-video-web.mp4"></source>        
    	</video>
        
      <script>
          document.getElementById('vid').play();
      </script>
        
        
    	<svg width="0" height="0" class="svg">
    		<defs>
      		<clipPath id="myClip">
      			<rect width="90" height="90" rx="45" fill="white"/>
      			<rect y="98" width="90" height="90" fill="white"/>
      			<rect y="196" width="90" height="90" fill="white"/>
      			<rect y="294" width="90" height="90" fill="white"/>
      			<rect x="98" width="90" height="90" fill="white"/>
      			<rect x="98" y="98" width="90" height="90" rx="45" fill="white"/>
      			<rect x="98" y="196" width="90" height="90" fill="white"/>
      			<rect x="98" y="294" width="90" height="90" fill="white"/>
      			<rect x="196" width="90" height="90" rx="45" fill="white"/>
      			<rect x="196" y="98" width="90" height="90" fill="white"/>
      			<rect x="196" y="196" width="90" height="90" fill="white"/>
      			<rect x="196" y="294" width="90" height="90" rx="45" fill="white"/>
      			<rect x="294" width="90" height="90" fill="white"/>
      			<rect x="294" y="98" width="90" height="90" rx="45" fill="white"/>
      			<path d="M384 286L339 196L294 286H384Z" fill="white"/>
      			<rect x="294" y="294" width="90" height="90" fill="white"/>
      		</clipPath>
    	  </defs>
    	</svg>
    
    </div>
  );
}

export default NM003;
