import React, { useState, useEffect } from 'react';
import { Route, withRouter, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import NM001 from "./NM001.js";
import NM002 from "./NM002.js";
import NM003 from "./NM003.js";
import './Fonts/fonts.css';
import './App.css';

function App() {
  const [isTouch, setIsTouch] = useState();

  useEffect(() => {
    setIsTouch(isMobile());
  }, []);

  const isMobile = () => {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    }
    if ('ontouchstart' in window) {
        return true;
    }
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content="No Moderation" />
        <meta property="og:url" content="https://nomoderation.com" />
        <meta property="og:description" content="Selected Works" />
        <meta property="og:image" content="https://nomoderation.com/og-image-home.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="No Moderation" />
        <meta name="twitter:description" content="Selected Works" />
        <title>No Moderation</title>
      </Helmet>
      <Route exact path="/" render={({ match }) =>
        <div 
          id="navContainer"
          className={isTouch ? "touch" : "no-touch"}>
          <nav className="sfui">
            <h1><a href="http://www.instagram.com/no_moderation" id="logo">No Moderation</a></h1>
            <ol>
              <li><Link to="/001">The Durability of the Delicate</Link></li>
              <li><Link to="/002">Club Clock with World Chess</Link></li>
              <li><Link to="/003">Packable Anorak with C'H'C'M'</Link></li>
            </ol>
          </nav>
        </div>
      }/>
      <Route exact path="/001" component={NM001}/>
      <Route exact path="/002" component={NM002}/>
      <Route exact path="/003" component={NM003}/>
    </React.Fragment>
  );
}

export default withRouter(App);
